import React from "react";

import { Text, Carousel, Box, Layer, Button } from "grommet";
import { PlayFill, Close } from "grommet-icons";

import angharadmckenzie from "../static/images/angharad-mckenzie.gif";
import janeaustin from "../static/images/jane-austin.gif";
import markmasterson from "../static/images/mark-masterson.gif";

const Speakers = (...data) => {
  const [open, setOpen] = React.useState();
  const [videoIndex, setVideoIndex] = React.useState(0);

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(undefined);
  const onChangeVideo = (count) => setVideoIndex(count);

  let listOfSpeakers = [
    {
      name: "Jane Austin",
      org: "Previously MOO",
      image: janeaustin,
      src:
        "https://player.vimeo.com/video/601770324?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
    {
      name: "Mark Masterson",
      org: "Previously Google Launchpad",
      image: markmasterson,
      src:
        "https://player.vimeo.com/video/557993945?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
    {
      name: "Angharad McKenzie",
      org: "Previously Charity:Water",
      image: angharadmckenzie,
      src:
        "https://player.vimeo.com/video/601755369?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    },
  ];

  let speakerCards = listOfSpeakers.map((speaker, i) => (
    <Box round="xsmall" background="dark-1">
      <Box>
        <img height="100%" src={speaker.image} alt="" />
      </Box>
      <Box pad="medium">
        <Text size="medium" weight="bold" color="white">
          {speaker.name}
        </Text>
        <Text size="medium" color="white">
          {speaker.org}
        </Text>
        <Box width="xsmall">
          <Button
            size="small"
            margin={{ top: "small" }}
            primary
            color="light-2"
            label="Watch"
            onClick={(event) => {
              onChangeVideo(i);
              onOpen();
            }}
            icon={<PlayFill size="small" />}
          />
        </Box>
      </Box>
    </Box>
  ));

  let layer = (
    <Layer
      id="hello world"
      position="center"
      onClickOutside={onClose}
      onEsc={onClose}
    >
      <Box align="end" round="full">
        <Box round="full">
        <Text>
          <Button
            size="small"
            margin={{ bottom: "xsmall" }}
            color="white"
            primary
            a11yTitle="Close"
            onClick={onClose}
            icon={<Close size="small" />}
          />
          </Text>
        </Box>
      </Box>
      <Box gap="small" width="xlarge">
        <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
          <iframe
            src={listOfSpeakers[videoIndex].src}
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            title="The secret life of data in the digital economy (Rowland Manthorpe, WIRED)"
          ></iframe>
        </div>
      </Box>
    </Layer>
  );

  // size from responsive.context in index.js
  let size = data[0].size;
  return (
    <>
      {size !== "small" ? (
        <Box gap="medium" direction="row">
          {speakerCards}
        </Box>
      ) : (
        <Carousel controls={false} play={2500}>
          {speakerCards}
        </Carousel>
      )}
      {open && layer}
    </>
  );
};

export default Speakers;
