import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Box } from "grommet";

export default function Clients() {
  const data = useStaticQuery(graphql`
    query LogoQuery {
      allFile(filter: { relativeDirectory: { eq: "clients" } }) {
        nodes {
          id
          name
          childImageSharp {
            gatsbyImageData(
              width: 150
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  `);
  return (
    <Box
      direction="row"
      wrap={true}
      justify="center"
      flex={{ grow: "1" }}
      margin={{ vertical: "large" }}
    >
      {data.allFile.nodes.map((avatar, i) => {
        let image = getImage(avatar.childImageSharp.gatsbyImageData);
        return (
          <GatsbyImage key={i} image={image} alt={`logo for ${avatar.name} `} />
        );
      })}
    </Box>
  );
}
