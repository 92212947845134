import * as React from "react";

import { Link } from "gatsby";
import { Box, Text } from "grommet";

export default function Banner() {
  return (
    <Box
      basis="full"
      align="center"
      pad="small"
      direction="row"
      gap="medium"
      justify="around"
    >
      <Box
        background={{ color: "accent-2", opacity: "weak" }}
        gap="medium"
        pad={{ horizontal: "medium",vertical: "small" }}
        align="center"
        direction="row"
      >
        <Box
          flex={false}
          pad={{ horizontal: "xsmall", vertical: "xxsmall" }}
          round="xxsmall"
          width="auto"
          as="span"
          justify="center"
          background={{ color: "accent-2", opacity: "medium" }}
        >
          <Text style={{ letterSpacing: ".2em" }} size="xsmall" weight="bold">
            COMING SOON
          </Text>
        </Box>
        <Box direction="row-responsive">
          <Text as="span" size="xsmall" margin="none">
            twbSpaces debate&emsp;
          </Text>
          <Text as="span" size="xsmall" margin="none">
            <Link to="/join">
              <strong>Register for updates</strong>
            </Link>
          </Text>
        </Box>
      </Box>
    </Box>
  );
}
