import * as React from "react";

// components
import { Link } from "gatsby";
import "@fontsource/dm-sans/400.css";
import "@fontsource/dm-sans/500.css";
import ReactPlayer from "react-player/vimeo";
import styled from "styled-components"

import {
  Box,
  Button,
  Heading,
  Paragraph,
  ResponsiveContext,
  Text,
} from "grommet";

import { Globe, Info, Tools } from "grommet-icons";

import Seo from "../components/seo";
import Layout from "../components/layout";
import Clients from "../components/clients.js";
import Speakers from "../components/speakers.js";
import Quote from "../components/quote.js";
import Foot from "../components/foot.js";
import Banner from "../components/banner.js";

// assets
import workshop from "../static/images/BHF-full_05.jpeg";

// styles
import "../static/assets/styles.css";
const ResponsiveHeading = styled(Heading)`
  @media (max-width: 400px) {
    font-size: 1.9em;
    line-height: 1.3em;
  }
`
// markup
const IndexPage = (sizeOverride) => {
  let issues = [
    "Enable sustainable transport",
    "Fix the housing crisis",
    "Get to net zero",
    "Give people ownership of their data",
    "Tackle food poverty",
    "Rethink the education system",
    "Design a sustainable health and care system",
    "Use the ESGs to measure businesses differently",
    "Design a circular economy",
  ];

  return (
    <Layout>
      <Seo title="Bringing together experts to solve systemic problems | Together we're better" />
      <Box
        width="large"
        pad={{ top: "medium", bottom: "large", horizontal: "medium" }}
        alignSelf="center"
        align="center"
      >
      <Banner/>
        <ResponsiveHeading
          level="1"
          fill
          responsive={true}
          textAlign="center"
          size="medium"
          margin={{ vertical: "none" }}
        >
          A diverse community coming together to solve complex problems
        </ResponsiveHeading>
        <Paragraph textAlign="center" size="large">
          <strong>
            Thinkers, doers and makers, acting together to build a sustainable
            future
          </strong>
        </Paragraph>
        <Box gap="small" direction="row-responsive">
          <Link to="/join">
            <Button
              size="large"
              primary
              color="accent-2"
              label="Join the community"
            />
          </Link>
        </Box>
        <Paragraph
          textAlign="center"
          size="small"
          margin={{ top: "medium", bottom: "none" }}
        >
          twb is a not-for-profit with 2,000 supporters from public, private &
          third sectors.
        </Paragraph>
      </Box>
      <Box
        pad="medium"
        width="large"
        alignSelf="center"
        margin={{ bottom: "xlarge" }}
      >
        <ReactPlayer
          controls={true}
          width="100%"
          loop
          playing
          muted
          url="https://vimeo.com/557617104"
        />
      </Box>

      <Box
        fill
        background={{ dark: true, color: "dark-1" }}
        direction="column"
        pad="xlarge"
        align="center"
      >
        <Box width="xlarge">
          <Box gap="large" direction="row-responsive">
            <Box basis="1/3" gap="large" textAlign="center">
              <Text textAlign="center">
                <Globe size="large" color="brand" />
              </Text>
              <Text size="large" textAlign="center" fill>
                <strong>
                  Join the community, meet people and access great content
                </strong>
              </Text>
            </Box>

            <Box basis="1/3" gap="large" textAlign="center">
              <Text textAlign="center">
                <Info size="large" color="brand" />
              </Text>

              <Text size="large" textAlign="center" fill>
                <strong>
                  Be inspired through talks, workshops and diverse perspectives
                </strong>
              </Text>
            </Box>

            <Box basis="1/3" gap="large" textAlign="center">
              <Text textAlign="center">
                <Tools size="large" color="brand" />
              </Text>

              <Text size="large" textAlign="center" fill>
                <strong>
                  Collaborate and solve meaningful problems.
                  <br />
                  Make things happen.
                </strong>
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box fill align="center" margin={{ top: "medium" }}>
        <Clients />
      </Box>
      <Box pad="medium">
        <Box
          width="xlarge"
          justify="center"
          alignSelf="center"
          margin={{ bottom: "large" }}
          background="dark-1"
          direction="row-responsive"
          round="xsmall"
          gap="large"
          pad={{ vertical: "xlarge", horizontal: "large" }}
        >
          <Box direction="column" justify="center">
            <Heading level="2" size="large" fill margin={{ top: "none" }}>
              <span style={{ opacity: 0.4 }}>Less talk.</span>
              <br />
              All action.
            </Heading>
            <Paragraph size="large" fill>
              Dig deep into problems through discussion with world experts, get
              new perspectives and build out real solutions to move things
              forward.
            </Paragraph>
          </Box>
          <Box
            as="ul"
            className="unstyledList"
            direction="row"
            justify="center"
            wrap={true}
          >
            {issues.map((issue, i) => (
              <Box
                as="li"
                style={{ transform: `rotate(4deg)` }}
                flex="grow"
                basis="1/4"
                height={{ min: "xsmall" }}
                margin={{ horizontal: "xsmall", bottom: "small" }}
                justify="center"
                background="#ffec02"
                pad="small"
              >
                <Text size="small" textAlign="center" weight="bold">
                  {issue}
                </Text>
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          margin={{ top: "large" }}
          width="xlarge"
          alignSelf="center"
          justify="center"
        >
          <ResponsiveContext>
            {(size) => <Speakers size={size} />}
          </ResponsiveContext>
        </Box>
        <Box
          width="xlarge"
          alignSelf="center"
          direction="row-responsive"
          justify="around"
          margin={{ top: "large", bottom: "large" }}
        >
          <Box basis="1/3" width={{ min: "medium" }}>
            <Heading level="2" size="large" fill margin={{ top: "none" }}>
              Learn & grow together
            </Heading>
          </Box>
          <Box basis="1/2">
            <Paragraph size="xlarge" margin={{ top: "none" }}>
              Get free access to over 15 hours of twb talks, and priority access
              to all future content. Join the twb Slack to bounce ideas, answer
              questions and network with our diverse community.
            </Paragraph>
            <Box width="medium">
              <Link to="/join">
                <Button
                  size="large"
                  primary
                  color="accent-2"
                  label="Join the community"
                />
              </Link>
            </Box>
          </Box>
        </Box>
        <Box
          width="xlarge"
          alignSelf="center"
          margin={{ top: "large", bottom: "medium" }}
          background="dark-1"
          round="xsmall"
          pad={{ vertical: "large", horizontal: "medium" }}
        >
          <Heading level="2" size="medium" fill textAlign="center">
            See the love for twb:
          </Heading>
          <Box width="large" alignSelf="center" gap="medium">
            <Quote
              testimonial="Just seeing across the piece what the opportunities are was just really inspiring, that’s the future and we need to learn from that"
              author="Francis Bacon, Samaritans"
            />

            <Quote
              testimonial={
                <>
                  Really loved today. Taking away ideas + inspiration. Speakers
                  did great, it was lit{" "}
                  <span aria-label="fire" role="img">
                    🔥
                  </span>{" "}
                  (I'm using 'lit' ironically...but it was lit) #twbconf`
                </>
              }
              author="Mel Hambarsoomian, previously MOO"
            />

            <Quote
              testimonial="Great to hear listening to users, telling stories, and focussing on the thing you do well emerging as big themes at #twbconf."
              author="Mark Masterson, previously Google Launchpad"
            />

            <Quote
              testimonial="If we do this from a human perspective, we have the opportunity to get this right"
              author="Jason Caplin, previously Barnardo's"
            />

            <Quote
              testimonial="Such a great opportunity to network and speak to people from within and outside of the sector"
              author="Claire Reynolds, previously Alzheimers Society"
            />

            <Quote
              testimonial="The only way we can achieve what we need to, is by collaborating"
              author="Audree Fletcher, previously Barnardo's"
            />
          </Box>
        </Box>
      </Box>

      <Box
        margin={{ vertical: "xlarge" }}
        pad="medium"
        background={{
          size: "cover",
          image: `url(${workshop})`,
        }}
      >
        <Box
          round="xsmall"
          alignSelf="center"
          width="medium"
          align="center"
          background="dark-1"
          margin={{ vertical: "xlarge" }}
          pad={{ vertical: "large" }}
        >
          <Heading
            level="2"
            size="small"
            textAlign="center"
            margin={{ top: "none" }}
          >
            Ready to get involved?
          </Heading>

          <Link to="/join">
            <Button
              size="large"
              primary
              color="accent-2"
              label="Join the community"
            />
          </Link>
        </Box>
      </Box>
      <Box pad="medium">
        <Box
          round="xsmall"
          alignSelf="center"
          width="large"
          background="dark-1"
          margin={{ bottom: "large" }}
          border={{ color: "accent-1", size: "xsmall" }}
        >
          <Box
            pad={{ horizontal: "large", vertical: "medium" }}
            round="xsmall"
            gap="medium"
            margin={{ top: "medium", bottom: "large" }}
          >
            <Paragraph margin="none" size="large" fill>
              On a personal note...{" "}
            </Paragraph>

            <Paragraph margin="none" size="large" fill>
              It’s clear to anyone with their eyes open that there are systemic
              problems left, right and centre. From inequalities to poverty to
              the environment, these aren’t going away. But they have to get
              solved, somehow. Otherwise we all lose.
            </Paragraph>
            <Paragraph margin="none" size="large" fill>
              twb exists to turn positive thinking into action. By bringing
              together people from diverse backgrounds we can move the needle on
              the most meaningful and complex problems. Because no one person
              has the answers, but together we can solve anything.
            </Paragraph>
            <Paragraph margin="none" size="large" fill>
              In the last ten years we’ve run hundreds of events, created a
              community of over 2,000 makers, doers and thinkers. We’d be stoked
              if you were part of twb too.
            </Paragraph>
            <Paragraph margin="none" size="large" fill>
              Kieran & Phil
              <br />
              twb leads and Founders of Po3
            </Paragraph>
          </Box>
        </Box>
      </Box>
      <Foot />
    </Layout>
  );
};

export default IndexPage;
