import React from "react"

import { Text } from "grommet"
import styled from "styled-components";

const Blockquote = styled('blockquote')`
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`
const Cite = styled(Text)`
  display: block;
  width: 100%;
  text-align:center;
`

const Quote = (props) => {

  return (
    <Blockquote>
      <Text textAlign="center" as="p" fill size="xlarge" weight="500" margin={{bottom: "small"}}>
        &ldquo;{props.testimonial}&rdquo;
      </Text>
      <Cite textAlign="center" size="small" as="cite">
        {props.author}
      </Cite>
    </Blockquote>

  )
}

export default Quote
